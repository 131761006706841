import "./upload-test-run.scss";
import { AppStore, ModalStore, SettingsStore, TestRunStore } from "stores";
import { Button, Icon, Modal } from "semantic-ui-react";
import TestRunUploadEdit from "components/test-run-upload-edit";
import TestRunUploadForm from "models/forms/test-run-upload-form";
import { observer } from "mobx-react-lite";
import { createRef } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import { organizationUrl } from "../../../core/utils";

const testRunForm = new TestRunUploadForm();
const autoSelect = createRef<HTMLElement>();

ModalStore.uploadTestRun.onShown = () => {
  testRunForm.clearFormData();
  if (autoSelect.current)
    autoSelect.current.focus();
};

const UploadTestRun = observer(() => {
  const { uploadTestRun } = ModalStore;
  const history = useHistory();

  const runTest = async () => {
    const environment = SettingsStore.environments.find((e) => e.id == testRunForm.environmentId);

    testRunForm.submit(async (data) => {
      try {
        await TestRunStore.uploadTestRun({
          projectId: data.projectId,
          environmentId: data.environmentId,
          branch: data.branch,
          environment: environment.key,
          ref: data.commit == "latest" ? null : data.commit,
          file: data.file,
        });
        uploadTestRun.hide();
      } catch (e) {
        NotificationManager.error("Please confirm that it is in the valid junit xml format", "We weren't able to read this test file.");
      }
    }, true);
  };

  const account = AppStore.account;
  if (!account)
    return <div />;

  const currentPlan = account.currentPlan;
  const upsell = account.setupComplete && currentPlan.tier == 1;

  return (
    <Modal size="small" open={uploadTestRun.showing} id="new-test-run-modal">
      <Modal.Header>Upload Test Run</Modal.Header>
      <Modal.Content>
        {upsell &&
          <div className={"upsell"}>Want Testery to run your tests for you? <Button onClick={() => {
            history.push(organizationUrl("settings/payment"));
            uploadTestRun.hide();
          }} style={{ marginLeft: "10px" }} positive={true}>Upgrade Today</Button></div>
        }
        <TestRunUploadEdit form={testRunForm} projectElement={autoSelect} />
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={testRunForm.isSubmitting} onClick={() => uploadTestRun.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
        <Button loading={testRunForm.isSubmitting} color="green" onClick={runTest}>
          <Icon name="check" />Upload Test Run
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default UploadTestRun;
