import { Button, Card, Form, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import "./setup.scss";
import TestRunUploadForm from "../../models/forms/test-run-upload-form";
import { ProjectStore, TestRunStore } from "../../stores";
import Project, { TestFramework } from "../../models/project";
import { Environment } from "../../models/settings";
import React, { useEffect, useRef, useState } from "react";
import ProjectForm from "../../models/forms/project-form";
import FrameworkSelect from "../../components/form/framework-select";
import { toLowerWithDashes } from "../../core/utils";
import ReactGA from "react-ga4";
import getRandomName from "namesgenerator";
import { NotificationManager } from "react-notifications";
import JunitUploadHelpButton from "../../components/test-run-upload-edit/junit-upload-help-button";

const testRunUploadForm = new TestRunUploadForm();
const projectForm = new ProjectForm();

interface Props {
  next: () => any;
  project: Project;
  environment: Environment;
}

const UploadStep = observer(({ next, project, environment }: Props) => {
  const [fileToUpload, setFileToUpload] = useState<File>(null);
  const [testingFramework, setTestingFramework] = useState<TestFramework>(null);
  const fileUploadContainerRef = useRef(null);

  useEffect(() => {
    setTestingFramework(project.testingFramework);
    projectForm.populate(project);
    testRunUploadForm.projectId = project.id;
    testRunUploadForm.environmentId = environment.id;
  });

  async function updateProjectName(event) {
    const value = event.target.value;
    if (!value || value.trim() === "") {
      const randomName = getRandomName();
      projectForm.updateField("name", randomName);
      projectForm.updateField("key", randomName.replace('_', '-').toLowerCase());
      return;
    }

    projectForm.updateField("name", value);
    projectForm.updateField("key", toLowerWithDashes(value));
    await ProjectStore.save(projectForm);
  }

  async function updateProjectTestFramework(testingFramework) {
    setTestingFramework(testingFramework);
    projectForm.updateField("testingFrameworkVersion", testingFramework === TestFramework.Cypress ? ">=10" : null);
    projectForm.updateField("testingFramework", testingFramework);
    projectForm.updateField("testArtifacts", testingFramework === TestFramework.NUnit ? ["spec.dll"] : []);
    projectForm.updateField("testPackages", testingFramework === TestFramework.TestNG ? ["tests"] : []);
    await ProjectStore.save(projectForm);
  }

  const runTestRun = async () => {
    try {
      await testRunUploadForm.submit(async (data) => {
        try {
          await TestRunStore.uploadTestRun({
            projectId: project.id,
            environmentId: environment.id,
            branch: data.branch,
            environment: environment.key,
            ref: data.commit == "latest" ? null : data.commit,
            file: data.file,
          });
        } catch (e) {
          NotificationManager.error("Please confirm that it is in the valid junit xml format", "We weren't able to read this test file.")
        }
      }, true);
      next();
    } catch (e) {
      fileUploadContainerRef.current.style.visibility = "visible";
    }
  };

  const runSampleTestRun = async () => {
    try {
      await testRunUploadForm.submit(async (data) => {
        await TestRunStore.uploadTestRunFromSample({
          projectId: project.id,
          environmentId: environment.id,
          branch: data.branch,
          environment: environment.key,
          ref: data.commit == "latest" ? null : data.commit,
          file: data.file,
        });
      }, true);
      next();
    } catch (e) {
      fileUploadContainerRef.current.style.visibility = "visible";
    }
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      testRunUploadForm.file = e.target.files[0];
      setFileToUpload(testRunUploadForm.file);
    }
  };

  ReactGA.event({
    category: "signup-onboarding",
    action: "upload-step",
  });

  return (
    <Form>
      <div className="upload">
        <h3>Project Details</h3>
        <Card>
          <Card.Content>
            <label>What's the name of your project?</label>
            <input id={"setup-project-name"} type={"text"} onBlur={updateProjectName} />
            <br />
            <br />
            <FrameworkSelect value={testingFramework} label={"What testing framework are you using?"}
                             onChange={async testFramework => await updateProjectTestFramework(testFramework)} />
          </Card.Content>
        </Card>

        <h3>Upload the test results you'd like analyzed.</h3>
        <strong>OPTION 1: </strong> Use One of Our Sample Files
        <Card>
          <Card.Content>
            <div>
              <Button onClick={runSampleTestRun}>Use the Sample</Button>
            </div>
          </Card.Content>
        </Card>

        <strong>OPTION 2: </strong> Upload a Test Result File (JUnit XML format)
        <Card>
          <Card.Content>
            <div>
              <label htmlFor="file" className="sr-only">
                <div style={{ display: "flex" }}>
                  <JunitUploadHelpButton />Upload the JUnit XML file you would like to analyze.
                </div>
              </label>
              <p>For more information on how to export JUnit XML for your framework, see: <a target="_blank"
                                                                                             href="https://docs.testery.io/how-to/export-test-results-in-junit-xml-format">How
                To Export Test Results in JUnit XML Format</a></p>
              <input id="file" type="file" onChange={handleFileChange} />
            </div>
            <br />
            <Button disabled={testRunUploadForm.isSubmitting || fileToUpload == null}
                    loading={testRunUploadForm.isSubmitting}
                    color="green" onClick={runTestRun}>
              <Icon name="check" />Upload Test Run
            </Button>
            <div className={"file-upload-error"} ref={fileUploadContainerRef}>
              File upload failed. Please make sure your test results are in JUnit.xml format.
            </div>
          </Card.Content>
        </Card>

        <strong>OPTION 3: </strong> Configure Your CI/CD to Upload Results for Automatic Analysis
        <Card>
          <Card.Content>
            <div>
              Once you've finished setting up your account, you'll also be able to use our CLI to upload test results
              for automatic analysis. We'll walk you through how to do this later. For now, just select one of the other
              options.
              <pre style={{ color: "#FFF" }}>
              pip install testery<br />
              testery upload-test-results ...
            </pre>
            </div>
          </Card.Content>
        </Card>
      </div>
    </Form>
  );
});

export default UploadStep;
