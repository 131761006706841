import { sinceText } from "core/date-utils";
import { Message } from "models/test-run";
import { UserStore } from "stores";
import "./message.scss";

interface Props {
  message: Message;
}

const MessageDisplay = ({ message }: Props) => {
  const user = UserStore.getUser(message.userId);

  return (
    <div className="message-display">
      <div className="user">{user?.fullName}</div>
      <div className="time">{sinceText(message.createdAt)} </div>
      <div className="message">{message.text}</div>
    </div>
  );
};

export default MessageDisplay;
