import { Account } from "models/account";
import { Button, Card, Icon, Label } from "semantic-ui-react";
import "./plan-select.scss";
import PlanForm from "models/forms/plan-form";
import { observer } from "mobx-react-lite";
import { Plan } from "../../../models/plan";
import { AppStore } from "../../../stores";
import { PromoCode, PromoCodeDuration } from "../../../models/promo-code";
import { CardElement } from "@stripe/react-stripe-js";
import { RefObject } from "react";
import { toCurrency } from "../../../core/utils";
import { AccountPlan } from "../../../models/account-plan";

interface Props {
  form: PlanForm;
  account: Account;
  windowsOnly?: boolean;
  linuxOnly?: boolean;
  newPlanTierSelection?: number;
  setNewPlanTierSelection: (newPlanTierSelection: number) => any;
  promoCode?: PromoCode;
  setAppliedPromoCode?: (promoCode?: PromoCode) => any;
  planForm?: PlanForm;
  changePayment?: boolean;
  validatePromoCode?: () => any;
  promoCodeError?: boolean;
  promoCodeRef?: RefObject<HTMLInputElement>;
  promoCodeSubmitting?: boolean;
  forSetup: boolean;
}

interface CardProps {
  currentPlanTier: number;
  currentPlanId?: number;
  originalMonthlyPrice: number;
  discountedMonthlyPrice: number;
  includes: string[];
  plan: Plan;
  newPlanTierSelection?: number;
  setNewPlanTierSelection: (newPlanTierSelection: number) => any;
  promoCode?: PromoCode;
  forSetup: boolean;
}

const PlanCard = ({
                    currentPlanTier,
                    currentPlanId,
                    originalMonthlyPrice,
                    discountedMonthlyPrice,
                    plan,
                    includes,
                    newPlanTierSelection,
                    setNewPlanTierSelection,
                    promoCode,
                    forSetup,
                  }: CardProps) => {

  let showAsCurrentPlan = currentPlanTier == plan.tier;
  if (AppStore.account.setupComplete)
    showAsCurrentPlan = currentPlanId && currentPlanId == plan.id;

  let buttonBackgroundColor = "#767676";
  let buttonTextColor = "#FFF";
  let buttonIcon: JSX.Element;
  let buttonText = "Current Plan";
  if (showAsCurrentPlan && newPlanTierSelection && plan.tier != newPlanTierSelection) {
    buttonBackgroundColor = "#FFF";
    buttonTextColor = "#767676";
  } else if (showAsCurrentPlan) {
    buttonBackgroundColor = "#FFF";
    buttonTextColor = "#1ebc30";
    buttonIcon = <Icon name={"check"} />;
  } else if (plan.tier == newPlanTierSelection) {
    buttonBackgroundColor = "#1ebc30";
    buttonIcon = <Icon name={"check"} />;
    buttonText = `${plan.name} Selected`;
  } else {
    buttonText = `Select ${plan.name}`;
  }

  function getDurationText(): string {
    if (!forSetup)
      return "";

    if (!promoCode)
      return "(after trial)";

    switch (promoCode.duration) {
      case PromoCodeDuration.forever:
        return "(after trial)";
      case PromoCodeDuration.once:
        return "for first month (after trial)";
      case PromoCodeDuration.repeating:
        return `for first ${promoCode.durationInMonths} months (after trial), then ${toCurrency(originalMonthlyPrice)}/mo`;
    }
  }

  return (<Card>
      <Card.Content>
        <Card.Header style={{ marginBottom: "20px" }}>{plan.name}</Card.Header>
        {promoCode && (
          <div
            className={`original-price-container${!promoCode.applicablePlanTiers.includes(plan.tier) ? " hidden" : ""}`}>
            <div className={"original-price"}>{toCurrency(originalMonthlyPrice)}</div>
            <div className={"original-price-line-through"} />
          </div>
        )}
        <div id={`tier-${plan.tier}-plan-discounted-price`} className="price">{toCurrency(discountedMonthlyPrice)}/mo</div>
        <div className={"promo-code-duration"}>{getDurationText()}</div>
        {promoCode && (
          promoCode.applicablePlanTiers.includes(plan.tier) ? (
            <div className={"promo-code-applied"}>Promo <strong>{promoCode.customerFacingCode}</strong> Applied!</div>
          ) : (
            <div className={"promo-code-not-applicable"}>Promo <strong>{promoCode.customerFacingCode}</strong> not
              applicable to this plan</div>
          )
        )}
        <div style={{ marginTop: "20px" }}>
          {includes.map((item, index) => (
            <div key={index} className="include">
              <Icon name={"check"} /> {item}
            </div>
          ))}
        </div>
      </Card.Content>
      <Card.Content extra>
        <Button
          style={{ background: buttonBackgroundColor, color: buttonTextColor }}
          onClick={() => {
            setNewPlanTierSelection(plan.tier == newPlanTierSelection ? null : plan.tier);
          }}
        >
          {buttonIcon}
          {buttonText}
        </Button>
      </Card.Content>
    </Card>
  );
};

const planIncludes = {
  1: ["Tai Analyzes Your Failed Tests", "Automated Analysis of 10 Test Runs", "CI/CD Integration"],
  2: ["Automated Analysis of 50 Test Runs", "Up to 30 Parallel Tests", "60,000 Automated Testing Minutes"],
  3: ["Automated Analysis of 150 Test Runs", "Up to 30 Parallel Tests", "150,000 Automated Testing Minutes"],
};

const LinuxPane = observer(({ form, newPlanTierSelection, setNewPlanTierSelection, promoCode, forSetup }: Props) => {
  return (
    <div>
      <Card.Group itemsPerRow={3}>
        {AppStore.availablePlans.map((plan) => {
          return <PlanCard key={plan.id}
                           currentPlanTier={newPlanTierSelection ? newPlanTierSelection : form.linuxPlanTier}
                           originalMonthlyPrice={plan.monthlyPrice}
                           discountedMonthlyPrice={getPriceAfterDiscount(plan, promoCode)}
                           newPlanTierSelection={newPlanTierSelection}
                           setNewPlanTierSelection={setNewPlanTierSelection}
                           includes={planIncludes[plan.tier]}
                           plan={plan}
                           currentPlanId={AppStore.account.currentAccountPlan?.planId}
                           promoCode={promoCode}
                           forSetup={forSetup} />;
        })}
      </Card.Group>
      {form.hasErrors("linuxPlanTier") && (
        <Label className="plan-error" color="red">
          {form.getErrorText("linuxPlanTier")}
        </Label>
      )}
    </div>
  );
});

export function getPriceAfterDiscount(plan: Plan, promoCode: PromoCode): number {
  if (!promoCode || !promoCode.applicablePlanTiers.includes(plan.tier))
    return plan.monthlyPrice;

  if (promoCode.percentOff) {
    return plan.monthlyPrice * ((100 - promoCode.percentOff) / 100);
  } else {
    return plan.monthlyPrice - promoCode.amountOff;
  }
}

export function getCurrentPlanAndPricingText(accountPlan: AccountPlan, plan: Plan) {
  let text = "";
  if (!accountPlan.promoCode) {
    text = `You are currently on the ${plan.name} plan and paying ${toCurrency(accountPlan.monthlyPrice)}/month.`;
  } else if (accountPlan.promoCodeDuration == PromoCodeDuration.forever) {
    text = `You are currently on the ${plan.name} plan using Promo Code ${accountPlan.promoCode} and paying ${toCurrency(accountPlan.monthlyPriceWithPromoCode)}/month.`;
  } else {
    if (accountPlan.promoCodeDuration == PromoCodeDuration.once)
      text = `You are currently on the ${plan.name} plan using Promo Code ${accountPlan.promoCode} and paying ${toCurrency(accountPlan.monthlyPriceWithPromoCode)}/month for the first month, then ${toCurrency(accountPlan.monthlyPrice)}/month after that.`;
    else
      text = `You are currently on the ${plan.name} plan using Promo Code ${accountPlan.promoCode} and paying ${toCurrency(accountPlan.monthlyPriceWithPromoCode)}/month for the first ${accountPlan.promoCodeDurationInMonths} months, then ${toCurrency(accountPlan.monthlyPrice)}/month after that.`;
  }
  return text;
}

// const WindowsPane = observer(({ form, account }: Props) => {
//   const windowsAnnualPricing = form.windowsRunners * prices.windows.annually;
//   const windowsMonthlyPricing =
//     form.windowsRunners *
//     (account.windowsMonthlyPriceOverride > 0 && account.windowsRunners == form.windowsRunners
//       ? account.windowsMonthlyPriceOverride
//       : prices.windows.monthly);
//
//   return (
//     <div>
//       <Card.Group itemsPerRow={3}>
//         <Card>
//           <Card.Content>
//             <Card.Header>Windows</Card.Header>
//             <div className="price">
//               ${form.monthly ? windowsMonthlyPricing : windowsAnnualPricing}
//               {windowsMonthlyPricing > 0 ? "/mo" : ""}
//             </div>
//             {!form.monthly && windowsAnnualPricing > 0 && (
//               <div className="billing-frequency">Billed Annually</div>
//             )}
//             <div className="include">Windows based servers</div>
//             <div className="include">Unlimited Testing Minutes</div>
//             <div className="include">CI/CD Integration</div>
//             <div className="include">Unlimited Users</div>
//             <NumberSelect
//               min={0}
//               max={100}
//               label="Number of Parallel Tests"
//               value={form.windowsRunners}
//               onChange={form.onChange("windowsRunners")}
//             />
//           </Card.Content>
//         </Card>
//       </Card.Group>
//       {form.hasErrors("windowsRunners") && (
//         <Label className="plan-error" color="red">
//           {form.getErrorText("windowsRunners")}
//         </Label>
//       )}
//     </div>
//   );
// });

const PlanSelect = observer(({
                               form,
                               account,
                               newPlanTierSelection,
                               setNewPlanTierSelection,
                               promoCode,
                               setAppliedPromoCode,
                               planForm,
                               changePayment,
                               validatePromoCode,
                               promoCodeSubmitting,
                               promoCodeError,
                               promoCodeRef,
                               forSetup,
                             }: Props) => {
  // const [total, setTotal] = useState<number>(0);

  // const windowsAnnualPricing = form.windowsRunners * prices.windows.annually;
  // const windowsMonthlyPricing =
  //   form.windowsRunners *
  //   (account.windowsMonthlyPriceOverride > 0 && account.windowsRunners == form.windowsRunners
  //     ? account.windowsMonthlyPriceOverride
  //     : prices.windows.monthly);

  // let total = form.monthly ? windowsMonthlyPricing : windowsAnnualPricing;
  // const linuxPlan = prices[form.linuxPlanTier];
  // if (linuxPlan) {
  //   total += form.monthly ? linuxPlan.monthly : linuxPlan.annually;
  // }

  // function setSelectedTotal() {
  //   const selectedPlan = AppStore.availablePlans.find(plan => plan.tier == form.linuxPlanTier);
  //   if (selectedPlan)
  //     setTotal(selectedPlan.monthlyPrice / 100);
  // }

  // useEffect(() => {
  //   AppStore.loadAvailablePlans()
  //     .then(() => {
  //       setSelectedTotal();
  //     });
  // });
  //
  // useEffect(() => {
  //   setSelectedTotal();
  // }, [form.linuxPlanTier]);

  // const panes = [
  //   {
  //     menuItem: { key: "linux", content: "Linux" },
  //     render: () => <LinuxPane form={form} account={account} />,
  //   },
  //   {
  //     menuItem: { key: "windows", content: "Windows" },
  //     render: () => <WindowsPane form={form} account={account} />,
  //   },
  // ];
  //
  // if (windowsOnly) {
  //   panes.shift();
  // } else if (linuxOnly) {
  //   panes.pop();
  // }

  const currentPlan = AppStore.account.currentPlan;
  const currentAccountPlan = AppStore.account.currentAccountPlan;
  const accountSetupComplete = AppStore.account.setupComplete;
  const linuxPane = <LinuxPane form={form} account={account} newPlanTierSelection={newPlanTierSelection}
                               setNewPlanTierSelection={setNewPlanTierSelection} promoCode={promoCode}
                               forSetup={forSetup} />;

  const content = accountSetupComplete && currentPlan && (currentPlan.name.toLowerCase().startsWith("legacy") || !currentPlan.monthlyStripeSubscriptionId || AppStore.account.windowsRunners > 0) ? (
    <div style={{ marginBottom: "5px" }}>
      You are currently on a custom plan. Please <a style={{ textDecoration: "underline", color: "#0048ff" }}
                                                    href={"https://testery.atlassian.net/servicedesk/customer/portal/1"}
                                                    target={"_blank"}>create a ticket</a> if you'd like to make plan
      changes.
    </div>
  ) : (
    accountSetupComplete ? (
      <>
        <div style={{ marginBottom: "5px" }}>
          {getCurrentPlanAndPricingText(currentAccountPlan, currentPlan)}
        </div>
        {linuxPane}
      </>
    ) : (
      linuxPane
    )
  );

  return (
    <div className="plan-select">
      {/*<Card className="billing-period">*/}
      {/*  <Card.Content>*/}
      {/*    <div className="period">Annually</div>*/}
      {/*    <Checkbox*/}
      {/*      slider*/}
      {/*      checked={form.monthly}*/}
      {/*      onClick={() => form.updateField("monthly", !form.monthly)}*/}
      {/*    />*/}
      {/*    <div className="period">Monthly</div>*/}
      {/*  </Card.Content>*/}
      {/*</Card>*/}
      {/*<Tab menu={{ secondary: true, pointing: true }} panes={panes} />*/}
      <Card.Group>
        <Card className="total">
          <Card.Content>
            {content}
          </Card.Content>
          {forSetup && (
            <Card.Content>
              <div className={"field promo-code-container"}>
                <input id={"signup-promo-code"} ref={promoCodeRef} onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    await validatePromoCode();
                  }
                }} disabled={promoCodeSubmitting || promoCode != null} className={"promo-code-input"} />
                {promoCode ? (
                  <Button style={{ background: "transparent", color: "#bc0000" }}
                          onClick={() => setAppliedPromoCode(null)}>
                    <Icon name={"x"} /> Remove promo code
                  </Button>
                ) : (
                  <Button positive={true} disabled={promoCodeSubmitting} loading={promoCodeSubmitting}
                          onClick={validatePromoCode}><Icon name={"check"} /> Apply Promo Code</Button>
                )}
              </div>
              <div className={`promo-code-error${promoCodeError && !promoCodeSubmitting ? " show" : ""}`}>
                Promo Code <strong>{promoCodeRef.current?.value?.toUpperCase()}</strong> is not currently valid
              </div>
            </Card.Content>
          )}
          {(promoCode == null || (promoCode && promoCode.requiresCreditCard) || !promoCode.applicablePlanTiers.includes(planForm.linuxPlanTier)) && (!account.setupComplete || (account.currentPlan.monthlyStripeSubscriptionId && (!account.cardLastFour || changePayment))) && (
            <Card.Content>
              <div className="field" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <label>Payment Information</label>
                <CardElement
                  id={"stripe-payment-info"}
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
                {forSetup && (
                  <div>(Your card will not be charged until the trial period has ended)</div>
                )}
                {planForm.hasErrors("cardToken") && (
                  <Label pointing prompt>
                    {planForm.getErrorText("cardToken")}
                  </Label>
                )}
              </div>
            </Card.Content>
          )}
        </Card>
      </Card.Group>
    </div>
  );
});

export default PlanSelect;
