import { observer } from "mobx-react-lite";
import "./setup.scss";
import { AppStore, SettingsStore, TestRunStore } from "../../stores";
import { useState } from "react";
import { Button } from "semantic-ui-react";
import { CardMedia } from "@mui/material";
import { useInitialEffect } from "../../core/react-utils";
import { AccountSetupStep, AccountSetupStepType } from "../../models/account-setup";
import Spinner from "components/spinner";
import { runInAction } from "mobx";
import { deletePromoCode, popSelectedPlanTier } from "../../core/storage";
import ReactGA from "react-ga4";

const FinalStep = observer(() => {
  const [loading, setLoading] = useState<boolean>(false);
  const pollDelay = 5 * 1000; // 5s

  useInitialEffect(async () => {
    setLoading(true);
    TestRunStore.getFirstTestRun()
      .then(async (testRun) => {
        await TestRunStore.createTestRunAnalysis(testRun.id);
        setTimeout(() => refreshAnalysis(testRun.id), pollDelay);
      });
  });

  async function refreshAnalysis(testRunId: number) {
    const result = await TestRunStore.getTestRunAnalysis(testRunId);
    if (result.status == "COMPLETE") {
      await SettingsStore.createAccountSetupStep(new AccountSetupStep(AccountSetupStepType.Final));
      await AppStore.loadAccount();
      runInAction(() => {
        setLoading(false);
        popSelectedPlanTier();
        deletePromoCode();
        window.location.href = `/${AppStore.selectedAccountName}/test-runs/${testRunId}/analysis`;
      });
    } else {
      setTimeout(() => refreshAnalysis(testRunId), pollDelay);
    }
  }

  function loadDocs() {
    window.open("https://docs.testery.io/integrations/ci-cd-integration-guide", "_blank");
  }

  ReactGA.event({
    category: "signup-onboarding",
    action: "final-step",
  });

  return (
    <div className={"final"}>
      <h3 id={"setup-complete-success-text"}>You did it! Depending on the number of failures you have, this can take a
        few minutes.</h3>
      <h4>We'll send you a notification as soon as the analysis is ready</h4>
      <CardMedia
        sx={{ height: 150, width: 160, margin: "auto" }}
        image="/img/tai-bot.png"
        title="Tai"
      />
      <Button positive disabled={loading}><><Spinner size={16} text="Analyzing Your Test Run" /></>
      </Button>
      <div>
        <p>To get the most out of testery, you'll want to add all of your test runs to the platform. </p>
        <Button onClick={loadDocs}>Learn how to connect your CI/CD to Testery</Button>
      </div>
    </div>
  );
});

export default FinalStep;
