import { useHistory, useParams } from "react-router";
import "./test-run-results.scss";
import { Counts } from "models/test-run";
import { observer } from "mobx-react-lite";
import { rerunWhileComponentLoaded, useInitialEffect } from "core/react-utils";
import { findIndex } from "lodash";
import {
  AppStore,
  ModalStore,
  ProjectStore,
  ReviewStore,
  SettingsStore,
  TestRunStore,
  TestSuiteStore,
  TriggerStore,
  UserStore,
} from "stores";
import { Tab, TabProps } from "semantic-ui-react";
import Page from "pages/page";
import TimelineTab from "./timeline-tab";
import HistoryTab from "./history-tab";
import { getUrlParam, organizationUrl, substringAfterLast } from "../../core/utils";
import TestResultsTab from "./test-results-tab";
import DetailsTab from "./details-tab";
import LogsTab from "./logs-tab";
import MessagesTab from "./messages-tab";
import TestRunOverview from "./test-run-overview";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import AnalysisTab from "./analysis-tab";
import JiraIssueForm from "../../models/forms/jira-issue-form";
import Spinner from "../../components/spinner";


let doRefresh = false;

async function refresh(testRunId: number, loadAll = true) {
  if (!doRefresh) return;

  const showLoading = !TestRunStore.hasTestRunResults(testRunId);
  const testRun = await TestRunStore.loadTestRun(testRunId);
  await TestRunStore.loadTestRunTests(testRunId, showLoading, loadAll);

  if (TestRunStore.hasRunningTests(testRun)) {
    let pollDelay = 5 * 1000; // 5s
    setTimeout(() => refresh(testRunId, true), pollDelay);
  }
}

const TestRunResultsPage = observer(() => {
  const testRunId = parseInt(useParams()["testRunId"]);
  const params = new URLSearchParams(window.location.search);
  const rootCauseId = parseInt(params.get("rootCauseId")) || 0;
  const testRun = TestRunStore.getTestRun(testRunId);
  const counts = new Counts();
  const history = useHistory();
  const testeryLiteEnabled = useFeatureIsOn("testery-lite");
  let tab = substringAfterLast(window.location.pathname, "/");
  if (!isNaN(Number(tab)))
    tab = "test-results";

  if (isNaN(testRunId)) {
    history.push(organizationUrl("/test-runs"));
    return <Spinner />;
  }

  const jiraIssueForm = new JiraIssueForm();

  useInitialEffect(async () => {
    await SettingsStore.loadAuthorizations();
    if (getUrlParam("createJira") !== "true")
      return;

    const path = window.location.pathname;
    const afterTestRuns = path.substring(path.indexOf("test-runs/") + 10);
    const testRunId = Number(afterTestRuns.substring(0, afterTestRuns.indexOf("/")));
    const testRunAnalysis = await TestRunStore.getTestRunAnalysis(testRunId);
    const rootCause = testRunAnalysis.testRunAnalysisRootCauses.find(t => t.id === rootCauseId);
    const testRunTestsAnalysis = testRunAnalysis.testRunTestAnalysis.filter(t => t.testRunAnalysisRootCauseId === rootCauseId);

    let description = "Affected Tests";
    testRunTestsAnalysis.forEach(t => description += `\n${window.location.origin + organizationUrl(`test-runs/${testRunId}/tests/${t.testRunTestId}`)}`);
    description += "\n\nSuggested fixes";
    testRunTestsAnalysis.forEach(t => JSON.parse(t.analysisText)["suggestions"].forEach(a => description += "\n" + a["text"]));

    jiraIssueForm.updateField("summary", `Fix ${rootCause.name}`);
    jiraIssueForm.updateField("description", description);
    jiraIssueForm.updateField("rootCauseId", rootCauseId);
    jiraIssueForm.updateField("testRunId", testRunId);
    jiraIssueForm.updateField("testRunTestIds", testRunTestsAnalysis.map(t => t.testRunTestId));
    ModalStore.createJiraModal.show(() => {}, jiraIssueForm);
  });

  useInitialEffect(async () => {
    await ProjectStore.ensureLoaded();
    TriggerStore.ensureLoaded();
    SettingsStore.ensureEnvironments();
    SettingsStore.loadRunnerConfigurations();
    TestRunStore.loadFollowers(testRunId);
    ReviewStore.loadReviewStatuses();
    UserStore.ensureUserRoles();
    TestSuiteStore.ensureLoaded();
    doRefresh = true;
    refresh(testRunId);
  });

  rerunWhileComponentLoaded(() => {
    TestRunStore.refreshMessages(testRunId);
  }, 30);

  const testFiles = TestRunStore.getTestRunResults(testRunId);

  testFiles.forEach((f) => counts.combine(f.counts));

  counts.checkMaxRunning(testRun, testFiles);

  if (!testRun) return null;

  const running = testRun?.notCompleted;

  const navigate = (data: TabProps) => {
    let path = "";
    if (data.activeIndex == 0) {
      path = organizationUrl(`test-runs/${testRunId}`);
    } else {
      path = organizationUrl(`test-runs/${testRunId}/` + tabPanes[data.activeIndex].menuItem.key);
    }
    history.push(path);
  };

  const tabPanes = [
    {
      menuItem: { key: "test-results", icon: "clipboard list", content: "Test Results" },
      render: () => <TestResultsTab key={1} testRun={testRun} refresh={refresh} testFiles={testFiles}
                                    running={running} rootCauseId={rootCauseId} />,
    },
    {
      menuItem: { key: "details", icon: "info", content: "Details" },
      render: () => <DetailsTab key={2} testRun={testRun} counts={counts} />,
    },
    {
      menuItem: { key: "history", icon: "history", content: "History" },
      render: () => <HistoryTab key={4} testRun={testRun} />,
    },
  ];
  if (!testRun.uploaded) {
    tabPanes.push({
      menuItem: { key: "logs", icon: "list alternate outline", content: "Logs (BETA)" },
      render: () => <LogsTab key={5} testRun={testRun} />,
    });
  }
  tabPanes.push({
    menuItem: { key: "messages", icon: "chat", content: "Messages" },
    render: () => <MessagesTab key={6} testRun={testRun} />,
  });
  if (testeryLiteEnabled) {
    tabPanes.push({
      menuItem: { key: "analysis", icon: "code", content: "Analysis" },
      render: () => <AnalysisTab key={7} testRun={testRun} counts={counts} running={running} />,
    });
  }
  if (AppStore.account.isLegacyOrTierTwoPlusPlan()) {
    tabPanes.splice(2, 0, {
      menuItem: { key: "timeline", icon: "clock outline", content: "Timeline" },
      render: () => <TimelineTab key={3} testRun={testRun} />,
    });
  }

  return (
    <Page
      name="test-run-results"
      title="Test Run Results"
      additionalCssClass={TestRunStore.loading ? "no-scroll" : null}
    >
      <TestRunOverview testRun={testRun} counts={counts} running={running} refresh={refresh} />
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={findIndex(tabPanes, (i) => i.menuItem.key == tab)}
        menu={{ secondary: true, pointing: true }}
        panes={tabPanes}
      />
      {/* NB: Doesn't matter where this is placed, so putting here at end out of the way. */}
      {/* Don't forget about the `no-scroll` class */}
      {TestRunStore.loading ? (
        <div className="big-spinner">
          <div className="spinner-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </Page>
  );
});

export default TestRunResultsPage;
