import "./project-settings.scss";
import { useInitialEffect } from "core/react-utils";
import { useHistory } from "react-router";
import { Button, Form, Grid, Icon, Label, Message, Popup, Segment } from "semantic-ui-react";
import RepoLink from "components/repo-link";
import YesNoSelect from "components/form/yes-no-select";
import NumberSelect from "components/form/number-select";
import BranchSelect from "components/form/branch-select";
import TagSelect from "components/form/tag-select";
import { AppStore, ModalStore, ProjectStore, SettingsStore, TestRunStore } from "stores";
import ProjectForm from "models/forms/project-form";
import { TESTNG_HELP_URL, UPLOAD_ARTIFACTS_HELP_URL } from "core/constants";
import { observer } from "mobx-react-lite";
import Project, { allowParallelizationOption, TestFramework } from "models/project";
import FrameworkInfo from "components/form/framework-info";
import ParallelizationSelect from "components/form/parallelization-select";
import NumberInput from "components/form/number-input";
import KeyInput from "components/form/key-input";
import FrameworkSelect from "components/form/framework-select";
import { organizationUrl } from "core/utils";

const projectForm = new ProjectForm();

async function attchTestCode(form: ProjectForm, push: (path: string) => any) {
  if (form.testingFramework == TestFramework.TestNG) {
    form.updateField("testPackages", ["testery.testng"]);
  } else if (form.testingFramework == TestFramework.NUnit) {
    form.updateField("testArtifacts", ["Web.Specs.dll"]);
    if (AppStore.account.windowsRunners == 0) {
      form.updateField("usingDotnetCore", true);
    }
  }
  const project = await ProjectStore.save(form, true);
  const build = await ProjectStore.attachTestCode(project.id);
  await TestRunStore.createTestRun({
    project: project.key,
    environment: SettingsStore.activeEnvironments[0].key,
    buildId: build.name,
  });
  push(organizationUrl("test-runs"));
}

interface Props {
  project?: Project;
}

const ProjectSettings = observer(({ project }: Props) => {
  const history = useHistory();

  const isNew = !project;
  const hasRepo = !!projectForm.gitRepo;

  useInitialEffect(() => {
    ProjectStore.ensureLoaded();
    SettingsStore.ensureEnvironments();
    projectForm.clearFormData();
    if (project) {
      projectForm.populateFromProject(project);
    }
  });

  const saveProject = async (form: ProjectForm) => {
    await ProjectStore.save(form);
    if (isNew) {
      history.push(organizationUrl("projects"));
    }
  };

  const showRepoSelect = () => {
    ModalStore.repoSelect.show((repo) => {
      if (repo) {
        projectForm.populateFromRepo(repo);
      } else {
        projectForm.clearRepo();
      }
    });
  };

  const showHelp =
    (projectForm.testingFramework || isNew) && (!hasRepo || projectForm.isCompiledFramework);

  const noTestCode = (
    <Message>
      Don't have any tests yet? You can run some of our sample project test code just to get
      started.
      <FrameworkSelect
        label="Testing Framework"
        error={projectForm.getErrorText("testingFramework")}
        value={projectForm.testingFramework}
        onChange={projectForm.onChange("testingFramework")}
      />
      <Button type="button" color="green" onClick={() => attchTestCode(projectForm, history.push)}>
        <Icon name="plus" />
        Run Sample Tests
      </Button>
    </Message>
  );

  return (
    <div className="project-settings">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form onSubmit={() => null}>
              <div className={"field" + (projectForm.hasErrors("gitRepo") ? " error" : "")}>
                <RepoLink project={projectForm} />
                <Button
                  type="button"
                  size="small"
                  color={hasRepo ? "yellow" : "green"}
                  onClick={showRepoSelect}
                >
                  <Icon name={hasRepo ? "edit" : "plus"} />
                  {hasRepo ? "Change" : "Add"} Repository
                </Button>
                <br />
                {projectForm.hasErrors("gitRepo") && (
                  <Label pointing prompt>
                    {projectForm.getErrorText("gitRepo")}
                  </Label>
                )}
              </div>
              <section>
                <Form.Input
                  label="Name"
                  error={projectForm.getErrorText("name")}
                  type="text"
                  value={projectForm.name}
                  onChange={projectForm.onChange("name")}
                />
                <KeyInput
                  label="Key"
                  error={projectForm.getErrorText("key")}
                  value={projectForm.key}
                  onChange={projectForm.onChange("key")}
                />
                <Form.TextArea
                  label="Description"
                  error={projectForm.getErrorText("description")}
                  type="text"
                  value={projectForm.description}
                  onChange={projectForm.onChange("description")}
                />
                <YesNoSelect
                  label="Does this project contain tests you will run on Testery?"
                  value={projectForm.hasTests}
                  onChange={projectForm.onChange("hasTests")}
                />
                {projectForm.hasTests && hasRepo && (
                  <BranchSelect
                    provider={projectForm.gitProvider}
                    owner={projectForm.gitOwner}
                    repo={projectForm.gitRepo}
                    value={projectForm.defaultBranch}
                    onChange={projectForm.onChange("defaultBranch")}
                    label="Default Branch"
                    error={projectForm.getErrorText("defaultBranch")}
                  />
                )}
                {projectForm.hasTests && (
                  <TagSelect
                    label="Known Tags"
                    error={projectForm.getErrorText("testTags")}
                    project={projectForm}
                    value={projectForm.testTags}
                    hideOptions={true}
                    onChange={projectForm.onChange("testTags")}
                  />
                )}
                {projectForm.hasTests && <FrameworkInfo form={projectForm} />}
                {projectForm.hasTests && (
                  <NumberSelect
                    label="Default Number of Parallel Tests"
                    error={projectForm.getErrorText("defaultMaxRunners")}
                    value={projectForm.defaultMaxRunners}
                    min={1}
                    max={AppStore.account.maxRunners(projectForm)}
                    onChange={projectForm.onChange("defaultMaxRunners")}
                    emptyOption
                  />
                )}
                {allowParallelizationOption(projectForm.testingFramework) && (
                  <ParallelizationSelect
                    label="Default Parallelization Strategy"
                    paralelizeByFile={projectForm.defaultToParallelizeByFile}
                    onChange={projectForm.onChange("defaultToParallelizeByFile")}
                    error={projectForm.getErrorText("defaultToParallelizeByFile")}
                  />
                )}
              </section>
              {projectForm.testingFramework == TestFramework.NUnit && (
                <div>
                  <section className="code-coverage">
                    <div className="title">Code Coverage</div>
                    <hr />
                    <YesNoSelect
                      inline
                      label="Using Code Coverage?"
                      error={projectForm.getErrorText("usingCodeCoverage")}
                      value={projectForm.usingCodeCoverage}
                      onChange={projectForm.onChange("usingCodeCoverage")}
                    />
                    {projectForm.usingCodeCoverage && (
                      <div>
                        <div className="coverage-row">
                          Code coverage is green when it's at least{" "}
                          <NumberSelect
                            menuPlacement="top"
                            onChange={projectForm.onChange("codeCoverageGreenPercentage")}
                            value={projectForm.codeCoverageGreenPercentage}
                            max={100}
                            min={0}
                          />{" "}
                          percent
                        </div>
                        <div className="coverage-row">
                          Code coverage is yellow when it's between{" "}
                          <NumberSelect
                            menuPlacement="top"
                            onChange={projectForm.onChange("codeCoverageGreenPercentage")}
                            value={projectForm.codeCoverageGreenPercentage}
                            max={100}
                            min={0}
                          />{" "}
                          and{" "}
                          <NumberSelect
                            menuPlacement="top"
                            onChange={projectForm.onChange("codeCoverageYellowPercentage")}
                            value={projectForm.codeCoverageYellowPercentage}
                            max={projectForm.codeCoverageGreenPercentage}
                            min={0}
                          />{" "}
                          percent
                        </div>
                        <div className="coverage-row text-only">
                          Code coverage is red when it's below{" "}
                          <NumberSelect
                            menuPlacement="top"
                            onChange={projectForm.onChange("codeCoverageYellowPercentage")}
                            value={projectForm.codeCoverageYellowPercentage}
                            max={projectForm.codeCoverageGreenPercentage}
                            min={0}
                          />{" "}
                          percent
                        </div>
                      </div>
                    )}
                  </section>
                </div>
              )}
              {projectForm.hasTests && (
                <div>
                  <Grid className="timeouts">
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <NumberInput
                          min={1}
                          max={1440}
                          label="Test Run Timeout (minutes)"
                          value={projectForm.testRunTimeoutMinutes}
                          onChange={projectForm.onChange("testRunTimeoutMinutes")}
                          error={projectForm.getErrorText("testRunTimeoutMinutes")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <NumberInput
                          min={1}
                          max={10800}
                          label="Test Timeout (seconds)"
                          value={projectForm.testTimeoutSeconds}
                          onChange={projectForm.onChange("testTimeoutSeconds")}
                          error={projectForm.getErrorText("testTimeoutSeconds")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <YesNoSelect
                    label="Retry Failed Tests"
                    error={projectForm.getErrorText("retryFailedTests")}
                    value={projectForm.retryFailedTests}
                    onChange={projectForm.onChange("retryFailedTests")}
                  />
                  <YesNoSelect
                    label={
                      <>
                        Are screenshot names outputted to test output?{" "}
                        <Popup
                          hoverable={true}
                          content={
                            <span>
                              Some times it can be difficult to match screenshots with the test it
                              was produced by. Printing out the name of the screen shot into your
                              test output helps ensure the screenshot gets associated with the right
                              test. If you set this to Yes, then Testery will only associate a
                              screenshot with a test if that screenshot name is in the output of the
                              test.
                            </span>
                          }
                          trigger={<Icon name="question circle" />}
                        />
                      </>
                    }
                    error={projectForm.getErrorText("screenshotNamesInOutput")}
                    value={projectForm.screenshotNamesInOutput}
                    onChange={projectForm.onChange("screenshotNamesInOutput")}
                  />
                  {projectForm.testingFramework == TestFramework.Cypress && (
                    <YesNoSelect
                      label="Record Video (BETA)"
                      error={projectForm.getErrorText("recordVideo")}
                      value={projectForm.recordVideo}
                      onChange={projectForm.onChange("recordVideo")}
                    />
                  )
                  }
                  <YesNoSelect
                    label="Cancel Queued/Submitted test runs if a newer, equivalent, test run is started?"
                    error={projectForm.getErrorText("cancelOlderTestRunsIfEquivalent")}
                    value={projectForm.cancelOlderTestRunsIfEquivalent}
                    onChange={projectForm.onChange("cancelOlderTestRunsIfEquivalent")}
                  />
                  <YesNoSelect
                    label={
                      <>
                        Automatically quarantine flaky tests?{" "}
                        <Popup
                          hoverable={true}
                          content={
                            <span>
                              Quarantined tests will still run but will be separated from your non-quarantined tests.
                              Quarantined tests will not be included in test run totals.
                            </span>
                          }
                          trigger={<Icon name="question circle" />}
                        />
                      </>
                    }
                    error={projectForm.getErrorText("quarantineFlakyTests")}
                    value={projectForm.quarantineFlakyTests}
                    onChange={projectForm.onChange("quarantineFlakyTests")}
                  />
                </div>
              )}
              <section className="form-actions">
                {isNew && (
                  <Button type="button" onClick={() => history.goBack()}>
                    <Icon name="times" />
                    Cancel
                  </Button>
                )}
                <Button
                  onClick={() => saveProject(projectForm)}
                  loading={projectForm.isSubmitting}
                  color="green"
                  type="submit"
                >
                  <Icon name="check" />
                  Save
                </Button>
              </section>
            </Form>
          </Grid.Column>
          <Grid.Column>
            {showHelp && (
              <Segment className="help" raised color="green">
                <Label color="green" ribbon>
                  Help
                </Label>
                {!hasRepo && !projectForm.isCompiledFramework && (
                  <>
                    <p>
                      Since you don't have an attached repository, to run tests in Testery you'll
                      first have to upload your test code.
                    </p>
                    <p>
                      <a target="_blank" href={UPLOAD_ARTIFACTS_HELP_URL}>
                        See our documentation on how to do that.
                      </a>
                    </p>
                    {noTestCode}
                  </>
                )}
                {projectForm.isCompiledFramework && (
                  <>
                    <p>
                      To run tests in Testery you'll first have to upload your compiled test
                      artifacts.
                    </p>
                    <p>
                      <a
                        target="_blank"
                        href={
                          projectForm.testingFramework == TestFramework.TestNG
                            ? TESTNG_HELP_URL
                            : UPLOAD_ARTIFACTS_HELP_URL
                        }
                      >
                        See our documentation on how to do that.
                      </a>
                    </p>
                    {!hasRepo && noTestCode}
                  </>
                )}
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
});

export default ProjectSettings;
