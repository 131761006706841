import { observer } from "mobx-react-lite";
import { Button, Icon } from "semantic-ui-react";
import "./setup.scss";
import { AccountSetupStep, AccountSetupStepType, ConnectChoice } from "../../models/account-setup";
import { SettingsStore } from "../../stores";
import { useState } from "react";
import { authorizeSlack, registerEmailAlert } from "../settings/integrations-tab";
import ReactGA from "react-ga4";

interface Props {
  next: () => any;
}

const ConnectStep = observer(({ next }: Props) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  async function handleChoice(choice: ConnectChoice) {
    switch (choice) {
      case ConnectChoice.slack:
        setSubmitted(true);
        await SettingsStore.createAccountSetupStep(new AccountSetupStep(AccountSetupStepType.Connect));
        authorizeSlack();
        setSubmitted(false);
        break;
      case ConnectChoice.email:
        setSubmitted(true);
        await registerEmailAlert();
        await SettingsStore.createAccountSetupStep(new AccountSetupStep(AccountSetupStepType.Connect));
        break;
    }

    next();
  }

  ReactGA.event({
    category: "signup-onboarding",
    action: "connect-step",
  });

  return (
    <div className={"connect"}>
      <h3>Your first test failure analysis is just minutes away...</h3>
      <div>
        <img src="/img/failure-analysis-slack.png" />
        <br/>
        <Button positive disabled={submitted} loading={submitted}
                onClick={() => handleChoice(ConnectChoice.slack)}><Icon
          name={"slack hash"} />Connect my Slack account</Button>
        {/*<h4>-- OR --</h4>*/}
        {/*<Button positive={false} onClick={() => handleChoice(ConnectChoice.email)}*/}
        {/*        style={{ background: "transparent", fontSize: ".9em" }}>Send the results to my email</Button>*/}
      </div>
      <br />
      <p><strong>Can't connect to Slack right now?</strong> <i>We recommend the Slack integration for the best experience. But if this isn't an option, you can also send the analysis to your inbox.</i></p>
      <div>
        <Button positive disabled={submitted} loading={submitted}
                onClick={() => handleChoice(ConnectChoice.email)}><Icon
          name={"mail square"} />Send the results to my email</Button>
      </div>
    </div>
  );
});

export default ConnectStep;
